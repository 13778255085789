import Image from 'next/image'
import type { ImageResult } from '../data/ImageFragment'
import { Container } from './Container'
import styles from './Header.module.sass'

export function Header(props: { title?: string; desc?: string; image?: ImageResult }) {
	return (
		<header className={styles.wrapper}>
			<div className={styles.background}>
				{props.image && (
					<Image
						src={props.image.url}
						alt={props.image.alt ?? ''}
						width={props.image.width}
						height={props.image.height}
					/>
				)}
			</div>
			<Container>
				<div className={styles.inner}>
					<h1>{props.title}</h1>
					<article>{props.desc}</article>
				</div>
			</Container>
		</header>
	)
}
