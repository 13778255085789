import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import type { ImageResult } from '../data/ImageFragment'
import type { LinkResult } from '../data/LinkFragment'
import { Container } from './Container'
import { Link } from './Link'
import styles from './SideBySide.module.sass'

export function SideBySide(props: {
	title?: string
	desc?: string
	image?: ImageResult
	link?: LinkResult
	imageView?: 'klenba' | 'klenbaSpodni' | 'klenbaRoh' | 'square'
	view?: 'left' | 'right'
}) {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div
					className={clsx(styles.inner, props.view && styles[props.view], props.imageView && styles[props.imageView])}
				>
					<AnimationOnScroll animateOnce className={styles.image} animateIn="animate__fadeInUp" offset={-300}>
						{props.image && (
							<Image
								src={props.image.url}
								alt={props.image.alt ?? ''}
								width={props.image.width}
								height={props.image.height}
							/>
						)}
					</AnimationOnScroll>
					<AnimationOnScroll animateOnce className={styles.content} delay={100} animateIn="animate__fadeInUp">
						<h3 className={styles.title}>{props.title}</h3>
						<article>{props.desc && <RichTextRenderer source={props.desc} />}</article>
						{props.link && <Link link={props.link} isBtn />}
					</AnimationOnScroll>
				</div>
			</Container>
		</div>
	)
}
