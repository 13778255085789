import type { LightboxSource } from '@mangoweb/fslightbox'
import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useMemo, useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import type { AsyncReturnType } from 'type-fest'
import type { ContentBlockReferencesBaseResult } from '../data/ContentBlockReferencesBaseFragment'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import styles from './Gallery.module.sass'

const loadLightbox = () => import('@mangoweb/fslightbox').then((module) => module.lightbox)

type Lightbox = AsyncReturnType<typeof loadLightbox>

export function Gallery(props: { items: ContentBlockReferencesBaseResult['gallery']; headline?: string }) {
	const [lightbox, setLightbox] = useState<null | Lightbox>(null)

	useEffect(() => {
		loadLightbox().then((lightbox) => setLightbox(() => lightbox))
	}, [])
	const gallery = useMemo(() => {
		if (lightbox && props.items) {
			const sources: LightboxSource[] = props.items.items.map((item) => ({
				source: String(item.image?.url),
				type: 'image',
				caption: '',
			}))

			return lightbox(sources)
		}
	}, [props.items, lightbox])

	return (
		<Container>
			<div className={styles.wrapper}>
				{props.headline && <h2>{props.headline}</h2>}

				<div className={clsx(styles.inner, Number(props.items?.items.length) < 3 && styles.viewTwo)}>
					{props.items?.items.map((item, index) => {
						return (
							<div key={item.id} className={styles.itemWrapper}>
								<AnimationOnScroll animateOnce animateIn="animate__fadeInUp" offset={-300}>
									{item.image?.url && (
										<div className={styles.imageWrapper} onClick={() => gallery?.open(index)}>
											<div className={styles.image}>
												<Image src={item.image?.url} alt={item.image?.alt ?? ''} fill />
											</div>
										</div>
									)}
									<div className={styles.link}>
										<ContemberLink link={item.link} />
									</div>
								</AnimationOnScroll>
							</div>
						)
					})}
				</div>
			</div>
		</Container>
	)
}
